import {Screenshot} from "app/landing/Screenshot";
import {styled} from "styled-components";

interface IProps {
  phone: string;
  tablet: string;
}

const Container = styled.div`
  &:hover {
    transform-origin: right top;
    transform: perspective(1000px) rotateX(10deg) rotateY(-10deg) rotateZ(2deg);
    transition: 0.5s ease;
  }

  transition: 0.5s ease;

  //background-color: red;
  //height: 100%;
  //height: 400px;
  //height: 100%;
  //position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  justify-content: center;
  //gap: 10px;
  //max-height: 100%;
  //max-width: 100%;
  //justify-items: first;
  //align-items: stretch;
  //justify-content: center;
  //min-height: 300px;
  //padding-bottom: 4%;
`;

const Tablet = styled(Screenshot)`
  //flex-basis: 200px;
  //margin-left: -10%;
  //margin-bottom: 7%;
  //position: relative;
  //height: 200px;
  max-height: 600px;
  margin-left: -260px;
  margin-bottom: 28px;
`;
const Phone = styled(Screenshot)`
  //flex-grow: 2;
  //margin-bottom: -4%;
  //margin-right: -2%;
  //position: absolute;
  //right: 50%;
  //margin-right: -10%;
  //bottom: 0;
  //scale: 150%;
  //width: 25%;
  max-width: 400px;
`;

export const PhoneAndTablet = ({phone, tablet}: IProps) => {
  return (
    <Container>
      <Tablet hover={false} image={tablet} tablet />
      <Phone horizontal hover={false} image={phone} />
    </Container>
  );
};
