import GenericPhone from "./assets/generic_phone.svg?react";
import GenericPhoneHoriz from "./assets/generic_phone_horiz.svg?react";
import GenericTabletHoriz from "./assets/generic_tablet.svg?react";
import {css, styled} from "styled-components";

interface IProps {
  image: string;
  tablet?: boolean;
  horizontal?: boolean;
  className?: string;
  hover?: boolean;
}

const shadow = css`
  display: inline-block;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.2));
  //&:hover {
  //  transform-origin: right top;
  //  transform: perspective(1000px) rotateX(10deg) rotateY(-10deg) rotateZ(2deg);
  //  transition: 0.5s ease;
  //}
  //transition: 0.5s ease;
`;

const hoverCss = css`
  &:hover {
    transform-origin: right top;
    transform: perspective(1000px) rotateX(10deg) rotateY(-10deg) rotateZ(2deg);
    transition: 0.5s ease;
  }

  transition: 0.5s ease;
`;

interface IScreenshotSC {
  $image: string;
  $hover?: boolean;
}

const StyleGenericPhone = styled(GenericPhone)<IScreenshotSC>`
  background-image: url(${({$image}) => $image});
  background-size: 95%;
  background-position: 50% 37%;
  background-repeat: no-repeat;
  ${shadow}
  ${({$hover}) => ($hover === false ? null : hoverCss)}
`;

const StyleHorizGenericPhone = styled(GenericPhoneHoriz)<IScreenshotSC>`
  background-image: url(${({$image}) => $image});
  background-size: 86%;
  background-position: 36% 50%;
  background-repeat: no-repeat;
  ${shadow}
  ${({$hover}) => ($hover === false ? null : hoverCss)}
`;

const StyleHorizGenericTablet = styled(GenericTabletHoriz)<IScreenshotSC>`
  background-image: url(${({$image}) => $image});
  background-size: 96%;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  ${shadow}
  ${({$hover}) => ($hover === false ? null : hoverCss)}
`;

export const Screenshot = ({image, className, horizontal, tablet, hover}: IProps) => {
  if (tablet) {
    return <StyleHorizGenericTablet $hover={hover} $image={image} className={className} />;
  } else {
    if (horizontal) {
      return <StyleHorizGenericPhone $hover={hover} $image={image} className={className} />;
    } else {
      return <StyleGenericPhone $hover={hover} $image={image} className={className} />;
    }
  }
};
