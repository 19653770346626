import {createPopper} from "@popperjs/core";
import {PropsWithChildren, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Instance} from "@popperjs/core/lib/types";

interface IProps {
  value: ReactNode;
  className?: string;
}

export const BootstrapDropdown = ({children, className, value}: PropsWithChildren<IProps>) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef<HTMLAnchorElement | null>(null);
  const target = useRef<HTMLDivElement | null>(null);
  const popperInstance = useRef<Instance | null>(null);

  useEffect(() => {
    if (target.current && tooltipRef.current) {
      popperInstance.current = createPopper(tooltipRef.current, target.current, {
        placement: "bottom-start"
      });
    }

    return () => {
      if (popperInstance.current) {
        popperInstance.current.destroy();
        popperInstance.current = null;
      }
    };
  }, []);

  useLayoutEffect(() => {
    popperInstance.current?.update();
  }, [visible]);

  const toggle = useCallback(() => setVisible((prevState) => !prevState), []);
  return (
    <>
      <a className={`dropdown-toggle ${className}`} href="#" onClick={toggle} ref={tooltipRef}>
        {value}
      </a>
      <div className={`dropdown-menu ${visible ? "show" : ""}`} onClick={toggle} ref={target}>
        {children}
      </div>
    </>
  );
};
